import { evaluatorTemplateParameters, getMessageContentString } from '../common/prompts';
import { Prompt, PromptVersion } from '../types';
import { PromptTypes, PromptVersionTypes } from '../types/Prompt';

export type ValidateVersionProps = {
  prompt: Prompt;
  version: PromptVersion;
};

/**
 * Validates a new version of a prompt.
 *
 * @param {ValidateVersionProps} props - The props object containing the prompt and version.
 * @param {Prompt} props.prompt - The prompt object.
 * @param {PromptVersion} props.version - The version object.
 * @returns {string | null} - A string if there is an error, otherwise null.
 */
export const useValidateNewVersion = ({ prompt, version }: ValidateVersionProps): string | null => {
  if (!prompt?.name.length) {
    return 'Name is required.';
  }

  if (version?.type === PromptVersionTypes.MESSAGING) {
    if (
      (!version?.messageTemplate?.messages.length ||
        !getMessageContentString(version?.messageTemplate?.messages[0]).length) &&
      !version?.messageTemplate?.systemPrompt?.trim().length
    ) {
      return 'Please add a message or system messaage to the messaging template.';
    }
  } else if (!version.template.trim().length) {
    return 'Template is required.';
  }

  return null;
};

/**
 * Validates the template of an evaluator prompt version.
 *
 * @param {ValidateVersionProps} props - The props object containing the prompt and version.
 * @param {Prompt} props.prompt - The prompt object.
 * @param {PromptVersion} props.version - The version object.
 * @returns {string[] | null} - An array of missing parameters if there are any, otherwise null.
 */
export const useValidateEvaluator = ({ prompt, version }: ValidateVersionProps): string[] | null => {
  if (prompt.type === PromptTypes.EVALUATOR) {
    const template =
      version.type === PromptVersionTypes.MESSAGING ? JSON.stringify(version.messageTemplate) : version.template;
    const missingParameters = evaluatorTemplateParameters.filter((p) => !template.includes(`{{${p}}}`));

    if (missingParameters.length) {
      return missingParameters;
    }
  }

  return null;
};
