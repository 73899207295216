import { PipelineEvaluator } from '../../types';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { PromptVersionSelector, StyledDialog } from '../common';
import { PromptTypes } from '../../types/Prompt';
import { getPrompt } from '../../services/Prompts';
import { getVersion } from '../../services/PromptVersions';
import { getErrorMessage } from '../../common/utils';
import toast from 'react-hot-toast';
import { PV } from '../common/PromptVersionSelector';
import { updateEvaluator } from '../../services/PipelineEvaluations';

/**
 * Props for the EvaluatorTile component.
 */
interface Props {
  evaluator?: PipelineEvaluator;
  isHidden?: boolean;
  onSave: (evaluator: PipelineEvaluator) => void;
  onClose: () => void;
}

/**
 * Renders a modify evaluator component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Evaluator} props.evaluator - The evaluator object to display.
 * @param {Boolean} props.isHidden - The flag to hide the tile.
 * @param {Function} props.onUpdate - The update event handler for the tile.
 * @returns {JSX.Element} The rendered EvaluatorTile component.
 */
const ModifyEvaluator: React.FC<Props> = (props: Props) => {
  const [evaluator, setEvaluator] = useState<PipelineEvaluator>();
  const [pv, setPV] = useState<PV>();
  const [newPv, setNewPv] = useState<PV>();
  const [newName, setNewName] = useState<string>('');
  const [isLoadingPromptVersions, setIsLoadingPromptVersions] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!props.evaluator) return;

    const _evaluator = props.evaluator;
    setNewName(_evaluator.name);
    setEvaluator(_evaluator);
    setIsLoadingPromptVersions(true);

    (async () => {
      // TODO: this is kinda kuldgy to just preselect a version, need to sort it out later in PromptVersionSelector
      try {
        if (_evaluator.promptId && _evaluator.promptVersionNumber) {
          const prompt = await getPrompt(_evaluator.promptId);
          const version = await getVersion(_evaluator.promptId, _evaluator.promptVersionNumber);
          setPV({ prompt, version });
          setNewPv({ prompt, version });
        }
      } catch (error) {
        const err = getErrorMessage(error);
        console.error(err);
        return toast.error(err);
      } finally {
        setIsLoadingPromptVersions(false);
      }
    })();
  }, [props.evaluator]);

  const handleVersionChange = (pv: PV) => setNewPv(pv);

  const handleOnCancel = () => {
    if (isSaving) return;
    props.onClose();
  };

  const handleOnConfirm = async () => {
    if (!evaluator || !newPv || !newPv.version) {
      return toast.error('Invalid evaluator.');
    } else if (newName === evaluator?.name && newPv.version.version === evaluator.promptVersionNumber) {
      console.log(newName, evaluator?.name, newPv?.version?.version, evaluator.promptVersionNumber);
      return toast.error('No changes detected.');
    }

    setIsSaving(true);

    try {
      await updateEvaluator(evaluator.pipelineId, evaluator.id, newName, newPv.version.version);
      const newEvaluator = { ...evaluator, name: newName, promptVersionNumber: newPv.version.version };
      props.onSave(newEvaluator);
      setEvaluator(newEvaluator);
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(err);
      return toast.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      title="Change Version"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Save'
        )
      }
      icon={faPencil}
      onClose={handleOnCancel}
      onConfirm={handleOnConfirm}
      isOpen={!props.isHidden}>
      {evaluator && (
        <div>
          <div className="my-2">
            <input
              id="evaluationName"
              name="evaluationName"
              type="text"
              autoComplete="off"
              value={newName}
              placeholder="Evaluator Name"
              onChange={(e) => setNewName(e.target.value)}
              required
              disabled={isSaving}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {isLoadingPromptVersions ? (
            <Skeleton count={2} />
          ) : (
            <PromptVersionSelector
              selected={pv}
              showArchivedButton={false}
              showPrompts={false}
              promptId={evaluator?.promptId}
              onChange={handleVersionChange}
              promptType={PromptTypes.EVALUATOR}
            />
          )}
        </div>
      )}
    </StyledDialog>
  );
};

export default ModifyEvaluator;
