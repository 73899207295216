import { AxiosError } from 'axios';
import { add, endOfMonth, startOfDay, startOfMonth, sub } from 'date-fns';
import { axiosInstance } from '../api';

const now = new Date();
const start = startOfDay(now);
const end = add(sub(start, { seconds: 1 }), { days: 1 });

/**
 * Formats API error responses based on API results.
 * @param error - The error response from the API.
 * @param fallback - The fallback error message to use if the error response is not recognized.
 * @returns The formatted error response or fallback error message.
 */
export const formatApiErrorResponse = (error: any, fallback?: string): string => {
  if (typeof error === 'string') {
    return error;
  } else if (Array.isArray(error)) {
    return error.map((e) => `${toTitleCase(e['loc'][1])}: ${e['message']}`).join('\n');
  } else {
    return fallback || 'An unknown error occurred.';
  }
};

/**
 * Handles API exceptions and returns a standard Error object.
 * @param error - The error object.
 * @param message - The custom error message to use if the error object does not provide a specific error message.
 * @returns A standard Error object with the error message.
 */
export const apiExceptionHandler = (error: unknown, message: string = 'An unknown error has occurred.') => {
  let errorMessage = message;
  if (error instanceof AxiosError && error.response) {
    errorMessage = formatApiErrorResponse(error.response.data?.detail, errorMessage);
  }
  console.error(error);

  return new Error(errorMessage);
};

/**
 * Extracts the error message from an error object.
 * @param error - The error object.
 * @returns The error message as a string.
 */
export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

/**
 * Extracts variables wrapped in {} from a string.
 * @param str - The string to extract variables from.
 * @returns An array of extracted variables.
 */
export const extractVariables = (str: string): string[] => {
  const regex = /{([^}]+)}/g;
  const matches = str.match(regex);

  return (matches ? matches.map((match) => match.slice(1, -1)) : []).filter(
    (value, index, self) => self.indexOf(value) === index
  );
};

/**
 * Sleeps for a given amount of time.
 * @param ms - The number of milliseconds to sleep.
 * @returns A Promise that resolves after the specified time.
 */
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Formats a number as currency.
 * @param amount - The number to format as currency.
 * @param decimals - The number of decimal places to include in the formatted currency.
 * @param currency - The currency code to use for formatting.
 * @returns The formatted currency string.
 */
export const formatCurrency = (amount: number | string, decimals: number = 2, currency: string = 'USD'): string =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, minimumFractionDigits: decimals }).format(
    Number(amount)
  );

/**
 * Converts a string to title case.
 * @param str - The string to convert.
 * @returns The converted string in title case.
 */
export const toTitleCase = (str: string): string =>
  str ? str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) : '';

/**
 * Converts a camelCase string to title case.
 * @param str - The string to convert.
 * @returns The converted string in title case.
 */
export const camelCaseToTitleCase = (str: string): string =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());

/**
 * Converts a snake_case string to camelCase.
 *
 * @param str - The snake_case string to convert.
 * @returns The camelCase version of the input string.
 */
export const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });

/**
 * Converts a camelCase string to snake_case.
 *
 * @param str - The camelCase string to convert.
 * @returns The snake_case version of the input string.
 */
export const camelToSnake = (str: string): string =>
  str.length === 0 || /^[a-z]+$/.test(str) ? str : str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Validates an email address.
 * @param email - The email address to validate.
 * @returns A boolean indicating whether the email address is valid.
 */
export const validateEmailAddress = (email: string): boolean => {
  const tester =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  const domainParts = address.split('.');

  if (
    domainParts.some((part) => {
      return part.length > 63;
    })
  ) {
    return false;
  }

  return tester.test(email);
};

/**
 * Validates a user password based on certain criteria.
 * @param password - The password to be validated.
 * @returns A string with an error message if the password is invalid, or undefined if the password is valid.
 */
export const validateUserPassword = (password: string): string | undefined => {
  const requiredLength = 8;

  // check password length requirement
  if (password.length < requiredLength) return `Password must be at least ${requiredLength} characters long`;

  // check that password has at least one lowercase letter
  if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter';

  // check that password has at least one uppercase letter
  if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter';

  // check that password has at least one number
  if (!/[0-9]/.test(password)) return 'Password must contain at least one number';

  // check that password has at least one special character
  if (!/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password))
    return 'Password must contain at least one special character';

  return undefined;
};

/**
 * Deep merges two objects, excluding missing properties from the source object. Returns a new object.
 * @param target - The target object to merge into.
 * @param source - The source object to merge from.
 * @returns The merged object.
 */
export const deepMergeExcludeMissing = (target: any = {}, source: any = {}): any => {
  if (!source) return target;

  const _target = { ...target };

  const merge = (target: any, source: any) => {
    Object.keys(source).forEach((key) => {
      if (key in target) {
        if (Array.isArray(target[key]) && Array.isArray(source[key])) {
          for (let i = 0; i < source[key].length; i++) {
            if (i >= target[key].length) {
              target[key].push(source[key][i]);
            } else if (typeof source[key][i] === 'object') {
              merge(target[key][i], source[key][i]);
            } else {
              target[key][i] = source[key][i];
            }
          }
        } else if (typeof target[key] === 'object' && typeof source[key] === 'object') {
          merge(target[key], source[key]);
        } else {
          target[key] = source[key];
        }
      }
    });
  };

  merge(_target, source);

  return _target;
};

/**
 * Checks if two values are deeply equal.
 * @param a - The first value to compare.
 * @param b - The second value to compare.
 * @returns Returns true if the values are deeply equal, false otherwise.
 */
export const deepEqual = (a: any, b: any): boolean => {
  if (a === b) return true;

  try {
    if (typeof a !== 'object' || typeof b !== 'object') return false;

    if (Object.keys(a).length !== Object.keys(b).length) return false;

    for (const key in a) {
      if (!(key in b)) return false;

      if (!deepEqual(a[key], b[key])) return false;
    }

    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Creates a deep copy of the given object or array.
 *
 * @param source - The object or array to be deep copied.
 * @returns A deep copy of the source object or array.
 */
export const deepCopy = <T, U = T extends Array<infer V> ? V : never>(source: T): T => {
  if (Array.isArray(source)) {
    return source.map((item) => deepCopy(item)) as T & U[];
  }
  if (source instanceof Date) {
    return new Date(source.getTime()) as T & Date;
  }
  if (source && typeof source === 'object') {
    return (Object.getOwnPropertyNames(source) as (keyof T)[]).reduce<T>(
      (o, prop) => {
        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
        o[prop] = deepCopy(source[prop]);
        return o;
      },
      Object.create(Object.getPrototypeOf(source))
    );
  }
  return source;
};

/**
 * Returns a function that accepts a property name and returns it.
 * @param _obj - The object to extract properties from.
 * @returns A function that accepts a property name and returns it.
 */
export const propertiesOf = <TObj>(_obj: TObj | undefined = undefined) => {
  return function result<T extends keyof TObj>(name: T) {
    return name;
  };
};

/**
 * Converts a CSV string to JSON format.
 * @param text - The CSV string to convert.
 * @param quoteChar - The character used for quoting values in the CSV. Defaults to '"'.
 * @param delimiter - The delimiter used to separate values in the CSV. Defaults to ','.
 * @returns The JSON representation of the CSV string.
 */
export const csvToJson = (text: string, quoteChar = '"', delimiter = ',') => {
  text = text.trim();
  let rows = text.split('\n');
  let headers = rows[0].split(delimiter).map((h) => h.trim());

  const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

  const match = (line: string) => {
    const matches = [...line.matchAll(regex)].map((m) => m[2]);
    const paddedMatches = Array.from({ length: headers.length }, (_, i) => matches[i] ?? null);

    return paddedMatches;
  };

  let lines = text.split('\n');
  const heads = headers ?? match(lines.shift() || '');
  lines = lines.slice(1);

  return lines.map((line) => {
    return match(line).reduce((acc, cur, i) => {
      const val = cur === null || cur.length <= 0 ? null : Number(cur) || cur;
      const key = heads[i] ?? `{i}`;
      return { ...acc, [key]: val };
    }, {});
  });
};

/**
 * Collection of date ranges for use in date pickers.
 */
export const DateRanges: Record<string, [Date, Date]> = {
  Today: [startOfDay(start), end],
  Yesterday: [startOfDay(sub(start, { days: 1 })), sub(end, { days: 1 })],
  'Last 5 Days': [startOfDay(sub(start, { days: 5 })), end],
  'Last 1 Week': [startOfDay(sub(start, { days: 7 })), end],
  'Last 3 Weeks': [startOfDay(sub(start, { days: 21 })), end],
  'Last 30 Days': [startOfDay(sub(start, { days: 30 })), end],
  'Last Month': [startOfMonth(sub(start, { months: 1 })), endOfMonth(sub(start, { months: 1 }))],
  'Last 2 Months': [startOfMonth(sub(start, { months: 2 })), endOfMonth(sub(start, { months: 1 }))],
  'Last 3 Months': [startOfMonth(sub(start, { months: 3 })), endOfMonth(sub(start, { months: 1 }))]
};

/**
 * Downloads a file from the specified URL and saves it with the given filename.
 * @param url - The URL of the file to download.
 * @param config - The Axios request configuration to use when downloading the file.
 * @throws Throws an error if an error occurs while downloading the file.
 */
export const downloadFile = async (url: string, config: any = undefined) => {
  try {
    const response = await axiosInstance.get(url, {
      responseType: 'blob',
      ...config
    });

    const filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
    const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${filename}.${extension}`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while downloading the requested data.');
  }
};

/**
 * Formats a user's name to first name and last initial.
 * @param name - The user's name to format.
 * @returns The formatted user name.
 */
export const formatUserName = (name: string | undefined): string => {
  if (!name) return 'Unknown';

  const names = name.split(' ');

  return names.length > 0 ? toTitleCase(`${names[0]} ${names[names.length - 1].charAt(0)}.`) : toTitleCase(names[0]);
};

/**
 * Clamps a number between a minimum and maximum value.
 *
 * @param value - The number to clamp.
 * @param min - The minimum value.
 * @param max - The maximum value.
 * @returns The clamped number.
 */
export const clampNumber = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

/**
 * Converts an array of strings into a sentence with proper punctuation.
 * Example: ['one', 'two', 'three'] => 'one, two, and three'
 *
 * @param arr - The array of strings to be converted.
 * @returns The sentence representation of the array.
 */
export const arrayToSentence = (arr: string[]): string => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr.join(' and ');

  const allButLast = arr.slice(0, -1).join(', ');
  const last = arr[arr.length - 1];

  return `${allButLast} and ${last}`;
};

/**
 * Checks if two arrays have the same contents regardless of order.
 *
 * @param arr1 - The first array to compare.
 * @param arr2 - The second array to compare.
 * @returns True if the arrays have the same contents, false otherwise.
 */
export const arrayContentsMatch = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

/**
 * Formats a command dilemeted decimal value to a string with a specified number of decimal places.
 *
 * @param value - The decimal value to format.
 * @param decimals - The number of decimal places to include in the formatted string. Defaults to 2.
 * @returns The formatted decimal value as a string.
 */
export const formattedDelimDecimal = (value: number | undefined, decimals: number = 2): string =>
  !value || isNaN(value)
    ? ''
    : value.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });

/**
 * Safely converts a number to a string with a fixed number of decimal places.
 *
 * @param value - The number to be converted.
 * @param decimals - The number of decimal places to round to. Default is 2.
 * @returns The converted number as a string with the specified number of decimal places.
 */
export const toFixedSafe = (value: number | undefined, decimals: number = 2): string =>
  !value || isNaN(value) ? '' : value.toFixed(decimals);

/**
 * Validates whether a given string is a properly formatted URL.
 *
 * @param url - The string to be validated as a URL.
 * @returns A boolean indicating whether the string is a valid URL.
 */
export const validateUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Colorizes the provided messages with the specified code.
 * @param code The ANSI color code.
 * @param ended Indicates whether to end the color sequence.
 * @param messages The messages to colorize.
 * @returns The colorized messages.
 */
export const colorize = new (class {
  color = (code: number, ended = false, ...messages: any[]) =>
    `\x1b[${code}m${messages.join(' ')}${ended ? '\x1b[0m' : ''}`;
  black = this.color.bind(null, 30, false);
  red = this.color.bind(null, 31, false);
  green = this.color.bind(null, 32, false);
  yellow = this.color.bind(this, 33, false);
  blue = this.color.bind(this, 34, false);
  magenta = this.color.bind(this, 35, false);
  cyan = this.color.bind(this, 36, false);
  white = this.color.bind(this, 37, false);
  bgBlack = this.color.bind(this, 40, true);
  bgRed = this.color.bind(this, 41, true);
  bgGreen = this.color.bind(this, 42, true);
  bgYellow = this.color.bind(this, 43, true);
  bgBlue = this.color.bind(this, 44, true);
  bgMagenta = this.color.bind(this, 45, true);
  bgCyan = this.color.bind(this, 46, true);
  bgWhite = this.color.bind(this, 47, true);
})();
