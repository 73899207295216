import CodeEditor from '@uiw/react-textarea-code-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { PromptVersion } from '../../types';
import { StyledDialog } from '../common';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

interface Props {
  version?: PromptVersion;
  isOpen: boolean;
  onClose: () => void;
  onSave: (helpers: Record<string, string>) => void;
}

/**
 * A modal component for managing template helpers.
 *
 * @component
 * @param {Props} props - The properties for the component.
 * @param {Object} props.version - The version object containing helpers.
 * @param {boolean} props.isOpen - A boolean indicating if the modal is open.
 * @param {function} props.onClose - A function to call when the modal is closed.
 * @param {function} props.onSave - A function to call when the helpers are saved.
 *
 * @returns {JSX.Element} The rendered modal component.
 *
 * @example
 * <PromptHelpersModal
 *   version={version}
 *   isOpen={isOpen}
 *   onClose={handleClose}
 *   onSave={handleSave}
 * />
 */
const PromptHelpersModal: React.FC<Props> = ({ version, isOpen, onClose, onSave }: Props) => {
  const [_hbsHelpers, setHbsHelpers] = useState<Record<string, string>>(version?.helpers || {});

  useEffect(() => {
    if (!version) return;
    setHbsHelpers(version.helpers || {});
  }, [version]);

  const handleHelperTitleChange = (oldKey: string, newKey: string) => {
    if (Object.keys(_hbsHelpers).includes(newKey)) {
      return toast.error(`Helper named "${newKey}" already exists`);
    }

    const newHelpers = { ..._hbsHelpers, [newKey]: _hbsHelpers[oldKey] };
    delete newHelpers[oldKey];

    setHbsHelpers(newHelpers);
  };

  const handleHelperChange = (key: string, value: string): void => {
    const newHelpers = { ..._hbsHelpers, [key]: value };

    setHbsHelpers(newHelpers);
  };

  const handleAddHelper = () => {
    const newKey = 'newHelper';
    if (Object.keys(_hbsHelpers).includes(newKey)) {
      return toast.error(`Helper named "${newKey}" already exists`);
    }

    setHbsHelpers({ ..._hbsHelpers, [newKey]: '()=>{}' });
  };

  const handleDelete = (key: string): void => {
    const newHelpers = { ..._hbsHelpers };
    delete newHelpers[key];

    setHbsHelpers(newHelpers);
  };

  return (
    <StyledDialog
      title="Template Helpers"
      isOpen={isOpen}
      closeText="Close"
      icon={faLifeRing}
      width="w-3/5"
      confirmText="Save"
      onConfirm={() => onSave(_hbsHelpers)}
      onClose={() => onClose()}>
      <div className="h-full overflow-auto pr-1">
        {Object.entries(_hbsHelpers).map(([key, value], i) => (
          <div key={i} className="mb-2 flex-grow">
            <div className="flex">
              <input
                type="text"
                value={key}
                onChange={(e) => handleHelperTitleChange(key, e.currentTarget.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-1"
              />
              <div className="flex-1 text-right content-center ml-4">
                <FontAwesomeIcon
                  icon={faTrash}
                  className="text-red-400 cursor-pointer hover:text-red-500"
                  onClick={() => handleDelete(key)}
                />
              </div>
            </div>
            <CodeEditor
              value={value}
              language="javascript"
              placeholder='{"log": "(s) => console.log(s)"}'
              onChange={(e) => handleHelperChange(key, e.target.value)}
              padding={10}
              className="w-full min-h-[125px] resize-none border-none p-3 focus:ring-0 .performance-editor"
              style={{
                backgroundColor: '#fff'
              }}
            />
          </div>
        ))}
        <div className="text-center mt-2">
          <button className="standard secondary" onClick={(e) => handleAddHelper()}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Helper
          </button>
        </div>
      </div>
    </StyledDialog>
  );
};

export default PromptHelpersModal;
